@import './colors';
@import './variables';
@import './extend';

.duff-secondary, .duff-primary {
    min-width: 178px;
    border-radius: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.5rem; // equals 24px
}

.duff-secondary {
    background-color: #fff;
    color: $dp-grey-455662;
}

.duff-primary {
    background-color: $dp-primary-blue;
    border: 0;

    &:hover,
    &:active,
    &:disabled,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        background-color: $dp-primary-blue;
    }
}

.button-container {
    display: flex;
    width: 100%;

    & .duff-primary {
        margin-left: auto;
    }
}

.action-button-container {
    display: flex;
}

.add-action-button {
    display: flex;
    color: $dp-primary-blue;
    cursor: pointer;
    margin: auto;
    border-bottom: 1px solid transparent;

    &:hover {
        border-bottom-color: $dp-light-blue;
    }

    & svg {
        fill: $dp-primary-blue;
        margin-right: 0.5rem;
        width: 12px;
    }
}

.action-button-solid {
    display: flex;
    color: white;
    cursor: pointer;
    margin-right: 0.9rem;
    margin-left: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-color: transparent;

    & svg {
        fill: white;
        margin-right: 0.5rem;
        width: 12px;
    }
}

.action-button-solid-medium {
    display: flex;
    color: white;
    cursor: pointer;
    margin-right: 0.9rem;
    margin-left: 0.9rem;
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
    border-color: transparent;

    & svg {
        fill: white;
        margin-right: 0.5rem;
        width: 12px;
    }
}

.action-button-solid-large {
    display: flex;
    color: white;
    cursor: pointer;
    margin-right: 3.0rem;
    margin-left: 3.0rem;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    border-color: transparent;
}

.action-button-transparent {
    display: flex;
    color: $dp-light-blue;
    cursor: pointer;
    margin-right: 0.9rem;
    margin-left: 0rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-top: 0.3rem;
    border-color: transparent;
    fill: transparent;
}

.button-margin-right {
    margin-right: 30px;
}

.button-margin-top {
    margin-top: .4em;
}

.button-float-right {
    float: right; 
    margin-top: .4em;
    background-color: $dp-primary-blue;
    border-style: solid;
    border-color: transparent;
}

.plus-add-icon {
    cursor: pointer;
    color: $dp-light-blue;
    padding-top: 0.4em;
}

.pearl-modal-primary-btn, button.pearl-modal-primary-btn {
    background-color: $dp-primary-blue;
    
    @extend .button-font-styling;

    border: 0;
    border-radius: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 35px;
    padding-left: 35px;
    text-align: center;
    height: 61px;

    &:disabled {
        background-color: $dp-primary-blue;
        opacity: 0.65;
    }

    &:focus {
        background-color: $dp-primary-blue;
    }
    
    span {
        display: inline-block
    }
}

.pearl-modal-primary-btn-small {
    background-color: $dp-primary-blue;
    
    @extend .button-font-styling;

    background-color: $dp-primary-blue;
    border: 0;
    border-radius: 0;
    text-align: center;
    height: 40px;
    width: 150px;

    &:disabled {
        background-color: $dp-primary-blue;
    }

    &:focus {
        background-color: $dp-primary-blue
    }

    span {
        display: inline-block
    }
}

.pearl-modal-secondary-btn {
    margin-right: 46px;
    color: $dp-blue-36b4ea;
    background-color: transparent;
    border: 0;

    &:hover {
        color: $dp-light-blue;
        background-color: transparent;
    }
    
    @extend .button-font-styling;
}

.pearl-secondary-btn-label {
    color: $dp-blue-36b4ea;
    background-color: transparent;
    border: 0;

    &:hover {
        color: $dp-light-blue;
        background-color: transparent;
    }

    @extend .button-font-styling;
    font-family: $opensans-light;
    font-size: 1rem;
}

.blue-action-button {
    @extend .action-item-styling;

    &:first-of-type {
        margin-left: 8px;
    }
}

.pearl-modal-primary-btn-edit-task {
    background-color: $dp-primary-blue;
    
    @extend .button-font-styling;

    border: 0;
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 35px;
    padding-left: 35px;
    text-align: center;
    min-height: 61px;

    &:disabled {
        background-color: $dp-primary-blue;
        opacity: 0.65;
    }

    &:focus {
        background-color: $dp-primary-blue;
    }
    
    span {
        display: inline-block
    }
}