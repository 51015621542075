@import './../../_shared/_assets/scss/colors';
.my-content {
    width: 100%;
    flex: auto;
    display: flex;
    flex-flow: column;

    .top, .bottom {
        padding: 0px 60px;
    }
    .top {
        h1 {
            color: #465661;
            font-size: 28px;
            font-weight: normal;
            margin-top: 2.813rem;
        }
        h2 {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 24px;
            color: #6B7881;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .my-content-header {
            margin-bottom: 30px;
        }
    }
    .bottom {
        background-color: #eceeef;
        h1 {
            font-size: 22px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    h2 {
        letter-spacing: .07rem;
        font-size: 18px;
        font-weight: bold;
    }
    
    .bookmarks {
        display: flex;
        flex-direction: row;
        margin-left: -20px;
        margin-right: -20px;
        flex-wrap: wrap;
        width: 75rem;
        .bookmark-wrapper {
            width: 23.20819%;
            height: 380px;
            margin: 0 10px 40px 10px;
         }
            .bookmark {
                position: relative;
                background: white;
                width: 100%;
                height: 380px;
                cursor: pointer;
                transition: opacity .4s cubic-bezier(.215, .61, .355, 1),
                border .4s cubic-bezier(.215, .61, .355, 1),
                transform .7s cubic-bezier(.215, .61, .355, 1);
                
                .header-image {
                    position: relative;
                    height: 175px;
                }
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
                .icon {
                    width: 20px;
                    color: $dp-green;
                    position: absolute;
                    bottom: 2px;
                    right: 14px;
                    font-size: 30px;
                }
                .icon-calendar {
                    width: 20px;
                    color: grey;
                    position: relative;
                    padding-right: 2px;
                    padding-left: 10px;
                    font-size: 20px;
                }
                .icon-location {
                    width: 20px;
                    color: grey;
                    position: relative;
                    padding-right: 2px;
                    padding-left: 10px;
                    font-size: 20px;
                }

                // example of container classes: bookmark insight-video
                &.insight-video:hover {
                    .icon-play {

                        &::after {
                            transform: scale(4);
                            opacity: 0;
                        }
                    }
                }

                // Example of usage
                // <Icon iconName="TriangleSolidRight12" className='icon icon-play' onClick={() => { }} />
                .icon-play {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 4.5rem;
                    width: 4.5rem;
                    font-size: 1.7rem;
                    color: #eb3023;
                    z-index: 10;
                    margin-top: -100px;
                    margin-left: 5px;
                    &::after {
                        content: "";
                        position: absolute;
                        left: -4%;
                        display: inline-block;
                        height: 100%;
                        width: 100%;
                        border-radius: 10rem;
                        background: #fff;
                        transition: all .2s;
                        opacity: .8;
                        z-index: -1;
                    }
                }

                .text {
                    padding: 20px;
                    height: 205px;
                    h2 {
                        font-size: 0.9rem;
                        font-family: 'OpenSans-Bold';
                        letter-spacing: 0;
                        color: #465661;
                        overflow: visible;
                        text-overflow: ellipsis;
                        text-transform: uppercase;
                        margin-bottom: 12px;
                    }

                    p {
                        font-size: 0.9rem;
                        color: #465661;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .news-Date{
                    position: absolute;
                    bottom: 1.065rem;
                    font-size: 0.65rem;
                    letter-spacing: 0.08rem;
                    line-height: 1.33333;
                    font-style: italic;
                }
            }

            .casestudy-bookmark{
                width: calc(46.41638% + 20px);
                border-top: 4px solid #13b5ea;
                border-radius: 2px;

                .leftStack{
                    width: 55%;
                    padding: 30px 10px 0 12px;
                    .category{
                        font-size: 0.8rem;
                        font-weight: 800;
                        letter-spacing: 3px;
                        line-height: 1.2;
                        color: #eb3023
                    }
                    .title{
                        font-size: 1.3rem;
                        line-height: 1.5;
                        letter-spacing: 0.025rem;
                        color: #465661;
                        p{
                            line-height: 1.5;
                            padding-top: 0.625rem;
                        }
                    }
                }

                .righttStack{
                    width: 45%;
                    border-left: 1px solid rgba(70,86,97,.1);
                    padding-left: 18px;
                    padding-right: 18px;
                    padding-top: 30px;
                    .image{
                        margin: auto 0;
                        max-width: 100%;
                    }
                    .rightText{
                        border-top: 1px solid #dadddf;
                        border-bottom: 1px solid #dadddf;
                        margin: auto 0;
                        padding: 12px 2px;
                        width: 100%;
                        opacity: .8;
                        letter-spacing: .02rem;
                        line-height: 1.33333;
                        color: #465661;
                    }
                }
            }
             .casestudy-bookmark:hover{
                border-top-width: 12px;
              }
            h3 {
                font-size: 14px;
                margin-bottom: 20px;
            }
    }    
    .explore {
        text-align: right;
        cursor: pointer;
        h2 {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: normal;
        }
        .chevron {
            width: 8px;
            position: relative;
            top: -2px;
            margin-left: 10px;
        }
    }
}