@import '../_shared/_assets/scss/colors';

.side-nav-list {
    .icon {
        font-size: 32px;
    }

    & .active-navigation-sidenav {
        background-color: #dadddf;
    }
}