$dp-red: #EB3023;
$dp-primary-blue: #14487F;;
$dp-light-grey:#eceeef;
$dp-off-white:#f6f6f7;
$dp-blue: #13b5ea;
$dp-purple: #6B1F7C;
$dp-light-blue: rgba(19, 181, 234, 1); 
$dp-blue-36b4ea: #36b4ea;

$dp-grey: #465661;
$dp-grey-b5bbc0: #b5bbc0;
$dp-grey-383838: #383838;
$dp-grey-656565: #656565;
$dp-grey-455662: #455662;

$facepile-light-blue: #00b0f0;
$dp-light-blue: rgba(19, 181, 234, 1);
$dp-facepile-blue: #003e69;
$dp-purple-status: #6B1F7C;
$dp-green: #43B049;
