@import '../../_assets/scss/colors';

.bell-notification-message {
  width: 100%;
  padding: 0.75rem;
  padding-top: 0rem;
  margin-bottom: 1rem;
  background-color: white;
  cursor: pointer;
  border: 2px solid #EFEFF6;
    .ms-Persona-initials{
      background-color: $dp-facepile-blue;
    }
}

.bell-message-header {
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0.25rem;
}

.bell-message-originator-persona {
  padding: 5px;
  margin-top: 0.5rem;
  margin-right: -0.75rem;
}

.bell-message-title {
  min-width: 0;
  width: 100%;
}

.bell-message-dismiss {
  display: inline-flex;
  justify-content: flex-end;
  margin-bottom: -0.75rem;
}

.bell-message-dismiss-button {
  width: 1rem;
}

.bell-message-dismiss-button:hover {
  color: red;
}


.bell-message-title-line {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.bell-message-title-text {
  padding-top: .7rem;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: medium;
}

.bell-message-origination-date {
  text-align: right;
  padding-right: 1rem;
}

.bell-message-subject-line {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: large;
}

.bell-message-body {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
}