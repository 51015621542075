@import '../../_assets/scss/colors';

.is-checked .ms-Checkbox-checkbox {
    background-color: $dp-blue !important;
    border: $dp-blue !important;
}

.ms-Panel-main {
    font-family: 'OpenSans-Regular';
}

.my-account {
    h1 {
        font-size: 1.8em;
        text-align: center;
    }
    h2 {
        font-size: 1.3em;
    }
    .external-link {
        cursor: pointer;
    }
    .external-link:hover {
        color: $dp-blue;
    }
    .signout {
        text-align: right;
        font-size: 20px;
        margin-bottom: 20px;
        margin-right: 14px;
    }
    .user-icon {
        user-select: none;
        width: 160px;
        height: 160px;
        line-height: 150px;
        color: white;
        background-color: $dp-facepile-blue;
        border-radius: 80px;
        text-align: center;
        font-weight: normal;
        margin: 40px auto;
        font-size: 50px;
        padding-left: 6px;
        font-family: 'OpenSans-Light';
    }
    .user-actions {
        width: 100%;
        display: inline-flex;
    }
    .user-details {
        border: 1px solid #979797;
        display: flex;
        flex-wrap: wrap;
        margin: 0 14px;
        font-size: 16px;
        padding: 24px;
        .external-link {
            color: $dp-blue;
            width: 50%;
            font-family: 'OpenSans-Bold';
            font-size: 14px;
            text-align: center;
        }
        .external-link:hover {
            text-decoration: underline;
        }
        .detail-item {
            flex-basis: 50%;
            padding-bottom: 24px;
            padding-right: 24px;
        }
        .detail-name {
            font-weight: bold;
            margin-bottom: 12px;
        }
    }
    .email-options {
        border: 1px solid #979797;
        margin: 30px 14px 0px 14px;
        font-size: 16px;
        padding: 24px;
        h2 {
            margin-bottom: 20px;
        }
        .ms-Checkbox {
            margin: 16px 0px;
        }
        button {
            background: $dp-primary-blue;
            color: white;
            width: 19em;
            margin: 40px auto 0px;
            display: block;
            height: 60px;
            border: 0;
        }
    }
    .not-implemented {
        opacity: .4;
        pointer-events: none;
    }
}
