@import '../_assets/scss/colors';

header {
    z-index: 88;
    display: flex;
    border-bottom: 1px solid #979797;
    position: fixed;
    width: 100%;
    height: 120px;
    background: white;
    .primary {
        height: 53px;
    }
    .secondary {
        height: 60px;
        display: flex;
        justify-content: flex-end;
    }
    
    .logo {
        flex: none;
        height: 7.4rem;
        width: 18.4rem;
        margin: 20px 0px 0px 0px;
    }
    .content {
        width: 100%;
        text-align: right;
        margin-right: 30px;
        .primary, .secondary {
            height: 50%;
            width: 100%;
        }
    }
    a {
        font-weight: bold;
        font-size: 0.85rem;
        letter-spacing: .04rem;
        margin: 0 24px;
        line-height: 60px;
    }
    .search {
        height: 40px;
        width: 334px;
        padding: 8px 10px;
        border: 2px solid #DADDDF;
        border-radius: 2px;
        margin: 6px 40px 6px 0px;
    }
    .search-icon {
        width: 20px;
        position: relative;
        left: -36px;
        path {
            fill: $dp-grey;
        }
    }
    .search::placeholder {
        color: #b5bbc0;;
        font-style: italic;
    }
    .icons {
        display: flex;
        .icon {
            font-size: 34px;
            align-self: center;
        }
        .icon, .user-icon {
            cursor: pointer;
            float: left;
            line-height: 1px;
            margin: 0 14px;
            user-select: none;
        }
        .apps-icon {
            font-size: 42px;
            top: 25px;
        }
        .apps-icon:hover, .apps-icon:active, 
        .notifications-icon:hover, .notifications-icon:active,
        .icon-active {
            color: $dp-blue;
        }
        .help-icon {
            font-size: 50px;
            top: 22px;
            margin-left: 16px;
        }
        .user-icon {
            width: 36px;
            height: 36px;
            line-height: 36px;
            color: white;
            background-color: $dp-facepile-blue;
            border-radius: 18px;
            text-align: center;
            display: inline-block;
            margin: 13px 12px 7px 22px;
            font-weight: normal;
            position: relative;
            top: -2px;
        }
    }
    .apps-list-container {
        z-index: 99;
        position: absolute;
        top: 117px;
        right: 0;
    }
    .apps-list {
        border-top: 3px solid $dp-blue;
        background-color: white;
        box-shadow: 0px 3px 6px 2px #ccc;
        padding: 20px;
        min-width: 300px;
        a {
            display: block;
            text-align: left;
            margin: 0;
            padding-left: 20px;
            line-height: 40px;
            font-size: 16px;
        }
        a:hover {
            text-decoration: underline;
        }
        hr {
            margin: 20px 20px;
        }
    }
}