.modal-backdrop {
    z-index: 5;
}

.modal {
    z-index: 10;

    & .modal-dialog {
        display: flex;
        max-width: initial;
        width: initial;

        & .modal-content {
            min-height: 500px;
            margin: auto;
        }

        & .modal-body {
            padding: 0;
            
            // TODO determine if this class can replace the current styling of the default form application wide
            @extend .form-modal
        }
    }
}

.modal-dialog.modal-large-content {
    & .modal-content {
        min-height: 600px;
    }
}

.modal-dialog-message-box {
    z-index: 10;

    & .modal-dialog {
        display: flex;
        max-width: initial;
        width: initial;
        padding-top: 10%;

        & .modal-content {
            min-width: 500px;
            min-height: 505px;
            width: initial;
            margin: auto;
        }
    }

    & .close {
        color: $dp-red;
        opacity: 1;
    }
}

.pearl-dialog-modal {
    min-width: 24.6rem;
    min-height: 14.6rem;
    color: $dp-grey;

    .dialog-modal-delete-icon {
        width: inherit;
        height: 2.6em;
    }

    .dialog-modal-body-txt {
        color: $dp-grey;
        font-size: 16px;
    }

    .pearl-modal-primary-btn {
        width: 9.65rem;
        height: 55px;
        font-size: 12px;
    }

    & [data-icon-name="Cancel"] {
        color: #dc3838;
    }
}
