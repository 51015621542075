/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    min-width: initial;
    width: 466px;
    height: 100%;
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

/*Left*/
.modal.left.fade .modal-dialog {
    left: -320px;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
    left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    border-radius: 0;
    border: none;
}

.modal-header {
    padding: 0;
    margin-bottom: 40px;
    font-size: 1.375rem;
    font-family: $opensans-light;
    letter-spacing: -0.12px;
    color: $dp-grey;
    border-bottom-color: transparent;
}