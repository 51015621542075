@import '../../_shared/_assets/scss/colors';

.ms-Panel-header{
    font-size: 1.528vw !important;
}
.admin-container{
    margin: 0 2rem;
    .content-header{
        flex: none;
    }

    h2{
        font-size: 1.375rem;
        padding: 1rem 0;
    }
}

.k-grid{
    .k-grid-header col:nth-of-type(1), .k-grid-table col:nth-of-type(1){
        width: 60%;
    }
    .k-grid-header col:nth-of-type(2), .k-grid-table col:nth-of-type(2){
         width: 15%;
    }
    .k-grid-header col:nth-of-type(3), .k-grid-table col:nth-of-type(3){
         width: 20%;
    }
    .k-grid-header col:nth-of-type(4), .k-grid-table col:nth-of-type(4){
         width: 5%;
    }
}

#announcement-modal{
    position: relative;
    label, .platform-label{
        font-size: 0.875rem;
    }
    .custom-label{
        font-weight: 600;
        padding: 5px 0;
    }
    .custom-left-label{
        padding: 5px 0;
        position: absolute;
        right: 0;
        top: 25px;
    }
    .announcement-text{
        .ms-TextField-fieldGroup{
            height: 7.813rem;
        }
    }
    .ms-StackItem
    {
        padding-top: 1rem;
        .ms-DatePicker{
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
    .row{
        width: 100%;
        flex-wrap: initial;
    }
    .save-btns{
        font-size: 0.972vw;
        margin-left: 35px;
        margin-top: 40px;
    }
    .pearl-modal-primary-btn{
        padding: 0 15px;
    }

    .addhyperlinkblue{
        color: $dp-blue-36b4ea;
    }
    .pearl-modal-primary-btn{
        min-width: 14.25rem;
    }
    .ms-MessageBar-innerText{
        white-space: normal;
    }
    .announcement-right-label{
        float: right;
        text-align: end;
        padding: 0;
    }
    .announcement-left-label{
        float: left;
        padding: 0;
    }
     .row{
        padding-bottom: 0.425rem;
        margin: 0;
        .col-10{
            padding: 0;
        }
    }
}