.announcement-banner{
    color: white;
    a, a:hover{
        color: white !important;
    }
    .row{
        margin: 0;
    }
    .announcement-close{
        text-align: end;
        i{
            cursor: pointer;
        }
    }
    .float-left{
        float: left;
    }
    .float-right{
        float: right;
    }
      .conditionalOpen {
        width: 100%;
        padding: 0.438rem;
        background-color: #6B1F7C;
              -webkit-animation: conditionalOpen 1s normal forwards ease-in-out;
                 -moz-animation: conditionalOpen 1s normal forwards ease-in-out;
                      animation: conditionalOpen 1s normal forwards ease-in-out;
              -webkit-transform-origin: 50% 0%;
                 -moz-transform-origin: 50% 0%;
                      transform-origin: 50% 0%;
          }
          .conditionalClose {
            width: 100%;
            padding: 0.438rem;
            background-color: #6B1F7C;
              -webkit-animation: conditionalClose 1s normal forwards ease-in-out;
                 -moz-animation: conditionalClose 1s normal forwards ease-in-out;
                      animation: conditionalClose 1s normal forwards ease-in-out;
              -webkit-transform-origin: 100% 0%;
                 -moz-transform-origin: 100% 0%;
                      transform-origin: 100% 0%;
          }
      
          // Opening and closing motion for conditional fieldset
          @-webkit-keyframes conditionalOpen {
              from { transform: scaleY(0) }
              to { transform: scaleY(1) }
          }
          @-moz-keyframes conditionalOpen {
              from { transform: scaleY(0) }
              to { transform: scaleY(1) }
          }
          @keyframes conditionalOpen {
              from { transform: scaleY(0) }
              to { transform: scaleY(1) }
          }
      
          @-webkit-keyframes conditionalClose {
              from { transform: scaleY(1) }
              to { transform: scaleY(0) }
          }
          @-moz-keyframes conditionalClose {
              from { transform: scaleY(1) }
              to { transform: scaleY(0) }
          }
          @keyframes conditionalClose {
              from { transform: scaleY(1) }
              to { transform: scaleY(0) }
          }
}