.my-content {
    width: 100%;
    flex: auto;
    display: flex;
    flex-flow: column;

    .top, .bottom {
        padding: 0px 3.75rem;
    }
    .top {
        h1 {
            color: #465661;
            font-size: 1.75rem;
            font-weight: normal;
            margin-top: 30px;
        }
        h2 {
            font-size: 1.125rem;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 24px;
            color: #6B7881;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .my-content-header {
            margin-bottom: 30px;
        }
    }
    .bottom {
        background-color: #eceeef;
        h1 {
            font-size: 22px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    h2 {
        letter-spacing: .07rem;
        font-size: 1.125rem;
        font-weight: bold;
    }
    .explore {
        text-align: right;
        cursor: pointer;
        float: right;
        padding-right: 4.5rem;
        padding-bottom: 1rem;
        h2 {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: normal;
        }
        .chevron {
            width: 8px;
            position: relative;
            top: -2px;
            margin-left: 10px;
        }
    }
}