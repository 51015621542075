.footer-container {
    padding: 20px 45px;
    font-size: 0.9rem;

    & .footer-useful-links {
        margin-bottom: 0;

        & > li {
            display: inline-block;
            margin: 0 21px;
        }
    }

    & .footer-copy {

        & p {
            margin-bottom: 0;
        }
    }
}
