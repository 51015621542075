main {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row;
    margin-top: 121px;
}
section {
    width: 100%;
    flex: auto;
    display: flex;
    flex-flow: column;
    margin-left: 250px;
    z-index: 1;
}

.mail-verification {
    box-sizing: border-box;
    font-size: 16px;
    width: 100vw;
}

.logo {
    height: 12.8vh;
    width: 31.625vw;
    margin-top: 18px;
}

article {
    width: 90vw;
    padding: 1.875rem 0 0 3.125rem;
    letter-spacing: 0.3px;
    font-family: 'Nunito Sans', sans-serif;


    .headerText {
        display: block;
        font-size: 2.25em;
        color: #14487F;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 7%;
    }

    .message {
        display: block;
        margin-top: 1%;
        font-size: 1.875em;

    }
    .refreshPage {
        padding-top: 1%;
        font-size: 1.875em;
        &__click {
            text-decoration: underline;
            cursor: pointer;
            color: #43B049;
        }
    }
}