.my-products {
    width: 100%;
    flex: auto;
    display: flex;
    flex-flow: column;
    .top, .bottom {
        padding: 0px 60px;
    }
    .top {
        padding-bottom: 30px;
        h1 {
            color: #14487F;
            font-size: 28px;
            font-weight: normal;
            margin-top: 2.813rem;
        }
        h2 {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 24px;
            color: #6B7881;
            margin-top: 20px;
        }
        .cards {
            margin-top: 30px;
        }
    }
    .card-section {
        background-color: #eceeef;
        h1 {
            color: #14487F;
            font-size: 24px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    .cards {
        display: flex;
        flex-direction: row;
        margin-left: -20px;
        margin-right: -20px;
        max-width: 1400px;
        flex-wrap: wrap;
        .card-wrapper {
            margin: 0 20px 40px 20px;
        }
        .card {
            cursor: pointer;
            background: white;
            width: 270px;
            height: 130px;
        }
        h2 {
            font-size: 18px;
            margin: 16px;
            font-weight: bold;
            display: inline-block;
        }
    }
    .explore {
        text-align: right;
        cursor: pointer;
        h2 {
            display: inline-block;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: normal;
        }
        .chevron {
            width: 8px;
            position: relative;
            top: -2px;
            margin-left: 10px;
        }
    }
}