.kendo-react-grid {
    max-height: 256px;
    min-height: 195px;
    overflow: auto;
    font-size: 0.875rem;
    letter-spacing: 0.08px;
    border: 0;

    .k-grid-header {
        background-color: transparent;
        border: 0;
        color: inherit;
    }

    & th.k-header {
        font-size: 1rem;
        letter-spacing: 0.09px;
        color: $dp-grey-656565;
        text-transform: uppercase;
        border: 0;
        margin: 0;
        padding: 0.375rem;

        &>.k-link {
            margin: 0;
            padding: 0;
        }
    }

    & .k-grid-header-wrap {
        border: 0;
        padding: 0;
        margin: 0;
    }

    & tbody {
        & .k-state-selected>td {
            background-color: $dp-light-blue;
            color: #fff;
        }

        & td {
            border: 0;
            padding: 0.375rem;
        }

        & tr:hover {
            cursor: pointer;
            & td, & td > .addHyperlinkBlue {
            background-color: $facepile-light-blue;
            color: #fff !important;
            }
        }
        & tr:hover > td > svg > path{
            fill: #fff !important;
        }
    }
}

.k-icon {
    padding-top: 0.438rem;
}

.k-i-sort-asc-sm:before {
    content: '\e004';
    color: $dp-grey;
}

.k-i-sort-desc-sm:before {
    content: '\e006';
    color: $dp-grey;
}

.k-master-row {
    background-color: $dp-off-white;
}

.k-grid .k-alt {
    background-color: transparent;
}

.kendo-react-virtual-grid {
    max-height: 490px;
    height: fit-content;
}

.kendo-grid-five-results {
    height: 186px;
}

.kendo-grid-multi-grid-page {
    height: 250px;
}