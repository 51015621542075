.div-float-right {
    position: relative;
    float: right; 
    margin-top: .4em;
    background-color: $dp-primary-blue;
    border-style: solid;
    border-color: transparent;
    
    .btn-primary:not(:disabled):not(.disabled).active {
        background-color: $dp-primary-blue;
        border-color: 0;
    }
}

.div-center {
    margin-top: .4em;
    background-color: $dp-primary-blue;
    border-style: solid;
    border-color: transparent;
}

.div-float {
    margin-top: 1.1em;
    margin-right: 1em;
    margin-left: 2em;
    background-color: transparent;
    border-color: transparent;
}

.badge-blue {
    color: $dp-light-blue;
    line-height: initial;
    font-size: 6em;
    padding-bottom: 0.3em;
}

.div-padded {
    margin-top: 4rem;
}

.editable-content-style {
    width: 100%;
    border: solid;
    // padding: 10px;
    background-color: #fff;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #b5bbc0;
    transition: 0.3s ease-in-out;
    font-size: 14px;
    line-height: 1.42857143;
    color: #323130;
    margin-bottom: 2px;
    font-weight: 400;
    font-family: 'Roboto',OpenSans-Light, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    & .content-text {
        padding: 10px;   
    }
    & .content-tag{
        background-color: rgba(253, 192, 6, 0.2);
        min-height: 2.1em;
        font-size: 12px;
        padding: 3px 3px 0;
        color:#FDC006;
        font-style: italic;
        line-height: 1.2em;
        font-weight:  700;
        align-items: center;
        display: flex;
        letter-spacing: 0.1em;
        justify-content: flex-start;
        & .content-icon{
            height: 1.2em;
            width: 1.2em;            
            margin: 0 3px;
        }       
    }
}

.scrollable-div {
    overflow-y: scroll;
    width: fit-content;
}

.scrollable-div-comment-box-edit-task {
    @extend .scrollable-div;
    height: 400px;
}