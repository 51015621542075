// Libraries
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@progress/kendo-theme-bootstrap/dist/all.css';
@import './colors';
@import './variables';
@import './extend';

@import './_typography';
@import './_footer';
@import './_utilities';
@import './kendo-react-grid';
@import './my-command-cell';
@import './modal';
@import './single-modal';
@import './buttons';
@import './divs';
@import './paragraph';

.bug {
    display: none;
}

html, body {
    height: 100%;
}

#react-root {
    display: flex;
    height: 100%;
    flex-direction: column;
}



 body {
     min-width: 1200px;
 }

::-webkit-scrollbar {
    width: 1rem;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #b5bbc0;
}

a:hover {
    text-decoration: none;
}

.banner {
    background-color: #6B1F7C;
}

.flex-auto {
    flex: auto;
}
.flex-column {
    display: flex;
    flex-flow: column;
}
.flex-row {
    display: flex;
    flex-flow: row;
}
.hover-box:hover {
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .15);
	-ms-transform: translateY(-6px)!important;
	transform: translateY(-6px)!important
}
.hover-box:active {
    -ms-transform: translateY(-3px)!important;
	transform: translateY(-3px)!important;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2)
}