.action-item-styling {
    color: $dp-blue-36b4ea;
    font-family: $opensans-regular;
    font-size: 1rem;
    letter-spacing: -0.18px;
    margin-left: 40px;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &:first-of-type {
        margin-left: 16px;
    }

    &:hover {
        border-bottom-color: $dp-light-blue;
    }
}

.facepile-font-styling {
    font-family: $opensans-light;
    font-size: 0.875rem;
    letter-spacing: -0.08px;
    color: #fff;
}

.button-font-styling {
    font-size: 0.875rem;
    letter-spacing: 0.6px;
    font-family: $opensans-bold;
    color: #fff;

    // used to override bootstrap styling of active and focus states
    &:focus {
        color: initial;
        background-color: initial;
        border-color: initial;
        box-shadow: initial;
    }

    &:not(:disabled):not(.disabled):active {
        color: initial;
        background-color: initial;
        border-color: initial;

        &:focus {
            box-shadow: initial;
        }
    }
}

// Used for extending on the .form-modal class
.relative-flex-width {
    position: relative;
    flex: 1 1 auto;
}

// Used to extend as the base styling for modal forms, and or forms in general
.form-modal {

    form {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;

        .textarea-container {
            margin-top: 45px;
        }

        div[class^="col"] {
            padding: 0;
        }

        .form-label {
            font-size: 1rem;
            letter-spacing: -0.18px;
            color: $dp-grey;
        }

        .form-label.sub-label {
            font-size: 0.875rem;
            letter-spacing: -0.08px;
            color: $dp-grey-383838;
        }

        .form-group {
            margin-bottom: 25px;
        }

        .form-group.form-group-no-margin-bottom {
            margin-bottom: 0;
        }

        .form-control,
        .k-widget.k-dropdown,
        .k-picker-wrap.k-state-default {
            font-size: 1rem;
            letter-spacing: -0.18px;
            height: 35px;
            border: 1px solid $dp-grey-b5bbc0;
            padding: 5px 10px;

            &::placeholder {
                color: $dp-grey-b5bbc0;
            }
        }

        .k-widget.k-dropdown {
            padding: 0;

            & .k-dropdown-wrap {
                border: 0;
            }
        }

        .k-picker-wrap.k-state-default {
            padding: 0;
        }

        .k-datepicker .k-picker-wrap .k-dateinput .k-dateinput-wrap .k-input {
            height: initial;
            border: 0;
        }


        .modal-button-layout {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;

            .form-group {
                margin-bottom: 0;
            }
        }

        .buttons-even-space {
            @extend .relative-flex-width;
            justify-content: space-evenly;
        }

        .buttons-flex-end {
            @extend .relative-flex-width;
            justify-content: flex-end;
        }
    }
}

.disabled {
    opacity: .3;
    pointer-events: none;
}

.inline-loader {
    margin-bottom: -200px;
}

.comment-box-height-edit-task {
    height: 400px;
}

.section-header-text {
    font-size: 1.5rem;
    letter-spacing: 0.09px;
    color: $dp-grey-656565;
    border: 0;
    margin: 0;
}