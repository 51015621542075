.flex-container {
    display: flex;
    flex: 1 1 auto;
}

.content-header{
    font-size: 2.25rem;
    margin: 2.688rem 0 1.875rem;
    padding-top: 0.563rem;
}

.action-item-container {
    display: flex;
    align-items: center;
    
    .action-item {
        color: $dp-blue-36b4ea;
        font-family: $opensans-regular;
        font-size: 1rem;
        letter-spacing: -0.18px;
        margin-left: 40px;
        cursor: pointer;
        border-bottom: 1px solid transparent;
    
        &:first-of-type {
            margin-left: 16px;
        }
    
        &:hover {
            border-bottom-color: $dp-light-blue;
        }
    }

    &.single-action-item-container {
        
        .action-item {

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

.text-center {
    text-align: center;
}