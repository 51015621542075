.paragraph-note {
    color: $dp-grey-b5bbc0;
    letter-spacing: -0.1px;
    font-size: 1.125rem;
}

.paragraph-note-bold {
    color: gray;
    letter-spacing: -0.1px;
    font-size: 1.7rem;
    font-family: $opensans-regular;
    text-align: center;
    padding-bottom: 2.118rem;
}