@import '../../_assets/scss/colors';

.notification-indicator-badge {
    top: 15px;
    right: -3px;
    transform: scale(1) translate(-180px, 70px);
    color: white;
    background-color: $dp-green;
    height: 20px;
    display: flex; 
    padding: 0 5px;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 0.75rem;
    width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 13px;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}