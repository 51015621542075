@import './_assets/scss/colors';

.side-nav-layout {

    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    letter-spacing: -0.27px;
    font-size: 1.1rem;
    position: fixed;
    z-index: 99;

    & .side-nav-toggle-container {
        width: 62px;
        background-color: #f6f6f7;
        min-height: calc(100vh - 120px); // height of screen minus header container
        height: 100%;
        padding: 0;
        transition: ease-in all 0.25s;

        &.side-nav-container-expanded {
            width: 250px;
        }
    }

    & .side-nav-toggle {
        padding-top: 5px;
        padding-left: 20px;
        min-height: 41px;
        cursor: pointer;

        & svg {
            position: absolute;
            height: 17px;
            top: 13px;
            right: 15px;
            transition: ease-in all 0.25s;
        }

        &.expanded svg {
            transform: rotate(180deg);
        }
    }

    & #side-nav-collapse {
        background-color: #465661;
        padding: 0;
    }

    & .side-nav-menu {
        & .nav-link {
            position: relative;
            overflow: initial;
            color: #465661;
            padding: 0.8rem 1rem;
            
            & svg {
                height: 26px;
                fill: #465661;
            }

            & > span {
                left: 40px;
                position: absolute;
                padding: 0;
                padding-left: 20px;
                min-width: 144px; // Used for transition clipping issues
                opacity: 0;
                transition: ease-in opacity 0.25s;
                font-size: 24px;
                line-height: 44px;
            }

            & .new-projects-container {
                position: absolute;
                right: -47%;
                top: 0;
                transition: ease-in all 0.35s;
                z-index: 2;

                &:hover {
                    & svg {
                        transform: rotate(180deg);
                    }
                }

                & > span {
                    position: absolute;
                    top: 47%;
                    right: 32%;
                    transform: translate(0, -47%);

                    & svg {
                        fill: #fff;
                    }
                }

                &.closing {
                    animation: moveRight 0.45s 1;
                }
            }

            &.expanded {
                overflow: initial;

                & > span {
                    animation: fadeIn 0.35s 1;
                    opacity: 1;
                }

                & .new-projects-container {
                    animation: moveRightExpanded 0.35s 1;
                    right: -7%;
                }
            }
        }

        & .nav-link-duff-grey {
            background-color: #dadddf;
        }
    }
}

@keyframes moveRight {
    0% {
        right: -7%
    }

    30% {
        right: -20%
    }

    60% {
        right: -30%
    }

    100% {
        right: -47%
    }
}
@keyframes moveRightExpanded {
    0% {
        right: -47%
    }

    30% {
        right: -30%
    }

    60% {
        right: -20%
    }

    100% {
        right: -7%
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0.75;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.25;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0.25;
    }

    50% {
        opacity: 0.5;
    }

    75% {
        opacity: 0.75;
    }

    100% {
        opacity: 1;
    }
}